import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

const Menu = () => {
    const { id } = useParams();
    const [menuItem, setMenuItem] = useState([]);
    const [shopdtl, seTshopdtl] = useState({})

    useEffect(() => {
        const fetchMenuItem = async () => {
            try {
                const response = await fetch(`https://quickcatalog.online/api/menu/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch menu item');
                }
                const result = await response.json();
                setMenuItem(result.products);
                seTshopdtl(result.shopDtl);
            } catch (error) {
                console.error('Error fetching menu item:', error);
            }
        };
        fetchMenuItem();
    }, [id]);

    return (
        <>
            <div style={{
                backgroundImage: `url('https://quickcatalog.online/uploads/${shopdtl.imageUrl}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
                color: '#fff',
            }}>
                <section className="innerpages-banner text-center innerpages-banner-hair">
                    <div className="container">
                        <div className="inner-banner-text text-center">
                        </div>
                    </div>
                </section>
            </div>


            <section className="dishes-menu">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 text-center mx-auto">
                            <div className="menu-section-title text-center">
                              <h1>{shopdtl.shopname}</h1>
                            <p>{shopdtl.address}</p>  

	                      <h2>Our Menu</h2>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div>
            <div className="card5">
                <div className="container mt-4">
                    <div className="row">
                    {menuItem.map((product, index) => (

                        <div className="col-md-6 mb-4" key={index}>
                            <div className='card'>

                                <div
                                    className="d-flex align-items-center"
                                    // onClick={() => openModal('menu-item-01.png')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img src={'https://quickcatalog.online/uploads/' + product.imageUrl} className="img-fluid me-3" style={{ width: '80px', height: '80px', objectFit: 'cover' }} alt="Product" />

                                    <div>
                                        <h2 className="h5 mb-1">{product.name}</h2>
                                        <p className="mb-1 text-muted">{product.price} ₹/</p>
                                        <h3 className="h6">Size: {product.sizes.map((size, idx) => <span key={idx}>{size}</span>)} </h3>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    ))}
                    </div>

                    

                </div>
            </div>
        </div>
                </div>
            </section>

        </>
    )
}

export default Menu
